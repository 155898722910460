import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { getAllTransitionsForEveryProcess } from '../../transactions/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities } from '../../util/data';

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/OrdersPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/OrdersPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/OrdersPage/FETCH_ORDERS_OR_SALES_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
  ownListings: [],
};

export default function ordersPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {

      const transactions = sortedTransactions(payload.transactions.data.data);
      const ownListings = payload.ownListings;
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.ownlistingsPagination,
        //pagination: payload.ownlistingsPagination.data.meta,
        ownListings: ownListings,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 10;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { tab } = params;

  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
  };

  const onlyFilter = onlyFilterValues[tab];
  if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for OrdersPage: ${tab}`));
  }

  dispatch(fetchOrdersOrSalesRequest());

  const { page = 1 } = parse(search);

  const apiQueryParams = {
    only: onlyFilter,
    lastTransitions: getAllTransitionsForEveryProcess(),
    include: [
      'listing',
      'stockReservation',
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
    ],
    'fields.transaction': [
      'processName',
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'lineItems',
    ],
    'fields.listing': ['title', 'availabilityPlan', 'publicData.listingType','publicData.userType', 'publicData.Condition', 'publicData.Storage'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    // perPage: INBOX_PAGE_SIZE,
  };

   return handleLoadOwnListingPage(dispatch, getState, sdk).then(res => {
    
    return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess({transactions: response, ownListings: res.data, ownlistingsPagination: res.meta}));
      return response;
    })
  })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};

export const handleLoadOwnListingPage = (dispatch, getState, sdk) => {
  const PageSize = 100;
  let PageNumber = 1;
  let data = [];
  let meta = [];


  const fetchListings = (PageSize, PageNumber) => {
   return sdk.ownListings
      .query({ per_page: PageSize, page: PageNumber, include: ["currentStock"] })
      .then(response => {
    
        meta = response.data.meta;
        // Handle response here, for example dispatching an action
        // data.push(response.data.data);
        var ownListingsResponse = denormalisedResponseEntities(response);

        data=[...data, ...ownListingsResponse];
        // Check if there are more results
        if (PageNumber < response.data.meta.totalPages) {
          // Increment page number for next iteration
          PageNumber++;
          // Fetch next page of listings
          return fetchListings(PageSize, PageNumber);
        }
        else {
          return  { data, meta };
        }

      })
      .catch(error => {
        // Handle error
        console.error('Error fetching own listings:', error);
      });
  };

  // Start fetching listings
  return fetchListings(PageSize, PageNumber);
};
