/**
 * Usage without sizes:
 *   <ResponsiveImage
 *     alt="ListingX"
 *     image={imageDataFromSDK}
 *     variants={['landscape-crop', 'landscape-crop2x']}
 *   />
 *   // produces:
 *   <img
 *     alt="ListingX"
 *     src="url/to/landscape-crop.jpg"
 *     srcSet="url/to/landscape-crop.jpg 400w, url/to/landscape-crop2x.jpg 800w" />
 *
 * Usage with sizes:
 *   <ResponsiveImage
 *     alt="ListingX"
 *     image={imageDataFromSDK}
 *     variants={['landscape-crop', 'landscape-crop2x']}
 *     sizes="(max-width: 600px) 100vw, 50vw"
 *   />
 *   // produces:
 *   <img
 *     alt="ListingX"
 *     src="url/to/landscape-crop.jpg"
 *     srcSet="url/to/landscape-crop.jpg 400w, url/to/landscape-crop2x.jpg 800w"
 *     sizes="(max-width: 600px) 100vw, 50vw" />
 *
 *   // This means that below 600px image will take as many pixels there are available on current
 *   // viewport width (100vw) - and above that image will only take 50% of the page width.
 *   // Browser decides which image it will fetch based on current screen size.
 *
 * NOTE: for all the possible image variant names and their respective
 * sizes, see the API documentation.
 */

import React from 'react';
import { arrayOf, oneOfType, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import NoImageIcon from './NoImageIcon';
import css from './ResponsiveImage.module.css';

import iPhone_11 from './images/iPhone_11.jpg';
import iPhone_11_Pro_Max from './images/iPhone_11_Pro_Max.jpg';
import iPhone_11_Pro from './images/iPhone_11_Pro.jpg';
import iPhone_12 from './images/iPhone_12.jpg';
import iPhone_12_mini from './images/iPhone_12_mini.jpg';
import iPhone_12_Pro from './images/iPhone_12_Pro.jpg';
import iPhone_12_Pro_Max from './images/iPhone_12_Pro_Max.jpg';
import iPhone_13 from './images/iPhone_13.jpg';
import iPhone_13_mini from './images/iPhone_13_mini.jpg';
import iPhone_13_Pro from './images/iPhone_13_Pro.jpg';
import iPhone_13_Pro_Max from './images/iPhone_13_Pro_Max.jpg';
import iPhone_14 from './images/iPhone_14.jpg';
import iPhone_14_eSim from './images/iPhone_14_eSim.jpg';
import iPhone_14_Plus from './images/iPhone_14_Plus.jpg';
import iPhone_14_Plus_eSim from './images/iPhone_14_Plus_eSim.jpg';
import iPhone_14_Pro from './images/iPhone_14_Pro.jpg';
import iPhone_14_Pro_eSIM from './images/iPhone_14_Pro_eSIM.jpg';
import iPhone_14_Pro_Max from './images/iPhone_14_Pro_Max.jpg';
import iPhone_14_Pro_Max_eSim from './images/iPhone_14_Pro_Max_eSim.jpg';
import iPhone_15 from './images/iPhone_15.jpg';
import iPhone_15_Plus from './images/iPhone_15_Plus.jpg';
import iPhone_15_Pro from './images/iPhone_15_Pro.jpg';
import iPhone_15_Pro_eSim from './images/iPhone_15_Pro_eSim.jpg';
import iPhone_15_Pro_Max from './images/iPhone_15_Pro_Max.jpg';
import iPhone_15_Pro_Max_eSim from './images/iPhone_15_Pro_Max_eSim.jpg';
import iPhone_SE_2020 from './images/iPhone_SE_2020.jpg';
import iPhone_SE_2022 from './images/iPhone_SE_2022.jpg';
import iPhone_X from './images/iPhone_X.jpg';
import iPhone_XR from './images/iPhone_XR.jpg';
import iPhone_XS from './images/iPhone_XS.jpg';
import iPhone_XS_Max from './images/iPhone_XS_Max.jpg';
import Samsung_Galaxy_A23_4G from './images/Samsung_Galaxy_A23_4G.jpg';
import Samsung_Galaxy_A23_5G from './images/Samsung_Galaxy_A23_5G.jpg';
import Samsung_Galaxy_A33_5G from './images/Samsung_Galaxy_A33_5G.jpg';
import Samsung_Galaxy_A34_5G from './images/Samsung_Galaxy_A34_5G.jpg';
import Samsung_Galaxy_A41 from './images/Samsung_Galaxy_A41.png';
import Samsung_Galaxy_A42_5G from './images/Samsung_Galaxy_A42_5G.png';
import Samsung_Galaxy_A51 from './images/Samsung_Galaxy_A51.jpg';
import Samsung_Galaxy_A51_5G from './images/Samsung_Galaxy_A51_5G.png';
import Samsung_Galaxy_A52 from './images/Samsung_Galaxy_A52.jpg';
import Samsung_Galaxy_A52_5G from './images/Samsung_Galaxy_A52_5G.png';
import Samsung_Galaxy_A52s_5G from './images/Samsung_Galaxy_A52s_5G.jpg';
import Samsung_Galaxy_A53_5G from './images/Samsung_Galaxy_A53_5G.jpg';
import Samsung_Galaxy_A54_5G from './images/Samsung_Galaxy_A54_5G.jpg';
import Samsung_Galaxy_A71 from './images/Samsung_Galaxy_A71.jpg';
import Samsung_Galaxy_A71_5G from './images/Samsung_Galaxy_A71_5G.jpg';
import Samsung_Galaxy_A72 from './images/Samsung_Galaxy_A72.jpg';
import Samsung_Galaxy_A72_5G from './images/Samsung_Galaxy_A72_5G.jpg';
import Samsung_Galaxy_Note10 from './images/Samsung_Galaxy_Note10.jpg';
import Samsung_Galaxy_Note10_5G from './images/Samsung_Galaxy_Note10_5G.jpg';
import Samsung_Galaxy_Note10_Plus from './images/Samsung_Galaxy_Note10_Plus.jpg';
import Samsung_Galaxy_Note20 from './images/Samsung_Galaxy_Note20.jpg';
import Samsung_Galaxy_Note20_5G from './images/Samsung_Galaxy_Note20_5G.jpg';
import Samsung_Galaxy_Note20_Ultra from './images/Samsung_Galaxy_Note20_Ultra.jpg';
import Samsung_Galaxy_Note20_Ultra_5G from './images/Samsung_Galaxy_Note20_Ultra_5G.jpg';
import Samsung_Galaxy_S10 from './images/Samsung_Galaxy_S10.jpg';
import Samsung_Galaxy_S10_Plus from './images/Samsung_Galaxy_S10_Plus.jpg';
import Samsung_Galaxy_S20 from './images/Samsung_Galaxy_S20.jpg';
import Samsung_Galaxy_S20_5G from './images/Samsung_Galaxy_S20_5G.jpg';
import Samsung_Galaxy_S20_FE from './images/Samsung_Galaxy_S20_FE.jpg';
import Samsung_Galaxy_S20_FE_5G from './images/Samsung_Galaxy_S20_FE_5G.jpg';
import Samsung_Galaxy_S20_Plus from './images/Samsung_Galaxy_S20_Plus.jpg';
import Samsung_Galaxy_S20_Plus_5G from './images/Samsung_Galaxy_S20_Plus_5G.jpg';
import Samsung_Galaxy_S20_Ultra_5G from './images/Samsung_Galaxy_S20_Ultra_5G.jpg';
import Samsung_Galaxy_S21_5G from './images/Samsung_Galaxy_S21_5G.jpg';
import Samsung_Galaxy_S21_FE_5G from './images/Samsung_Galaxy_S21_FE_5G.jpg';
import Samsung_Galaxy_S21_Plus_5G from './images/Samsung_Galaxy_S21_Plus_5G.jpg';
import Samsung_Galaxy_S21_Ultra_5G from './images/Samsung_Galaxy_S21_Ultra_5G.jpg';
import Samsung_Galaxy_S22_5G from './images/Samsung_Galaxy_S22_5G.jpg';
import Samsung_Galaxy_S22_Plus_5G from './images/Samsung_Galaxy_S22_Plus_5G.jpg';
import Samsung_Galaxy_S22_Ultra_5G from './images/Samsung_Galaxy_S22_Ultra_5G.jpg';
import Samsung_Galaxy_S23_5G from './images/Samsung_Galaxy_S23_5G.jpg';
import Samsung_Galaxy_S23_Plus_5G from './images/Samsung_Galaxy_S23_Plus_5G.jpg';
import Samsung_Galaxy_S23_Ultra_5G from './images/Samsung_Galaxy_S23_Ultra_5G.jpg';
import Samsung_Galaxy_S24_Ultra_5G from './images/Samsung_Galaxy_S24_Ultra_5G.png';
import Samsung_Galaxy_Z_Flip3_5G from './images/Samsung_Galaxy_Z_Flip3_5G.jpg';
import Samsung_Galaxy_Z_Flip4_5G from './images/Samsung_Galaxy_Z_Flip4_5G.jpg';
import Samsung_Galaxy_Z_Flip5 from './images/Samsung_Galaxy_Z_Flip5.jpg';
import Samsung_Galaxy_Z_Fold3_5G from './images/Samsung_Galaxy_Z_Fold3_5G.jpg';
import Samsung_Galaxy_Z_Fold4_5G from './images/Samsung_Galaxy_Z_Fold4_5G.jpg';
import Samsung_Galaxy_Z_Fold5 from './images/Samsung_Galaxy_Z_Fold5.jpg';
import Huawei_Mate_20_Pro from './images/Huawei_Mate_20_Pro.webp';
import Huawei_Mate_30_Pro from './images/Huawei_Mate_30_Pro.webp';
import Huawei_Mate_40_Pro_5G from './images/Huawei_Mate_40_Pro_5G.jpg';
import Huawei_Mate_50_Pro from './images/Huawei_Mate_50_Pro.jpg';
import Huawei_P20_Pro from './images/Huawei_P20_Pro.jpg';
import Huawei_P30 from './images/Huawei_P30.jpg';
import Huawei_P30_Lite from './images/Huawei_P30_Lite.jpg';
import Huawei_P30_Pro from './images/Huawei_P30_Pro.jpg';
import Huawei_P40 from './images/Huawei_P40.jpg';
import Huawei_P40_Lite from './images/Huawei_P40_Lite.jpg';
import Huawei_P40_Lite_5G from './images/Huawei_P40_Lite_5G.jpg';
import Huawei_P40_Pro_5G from './images/Huawei_P40_Pro_5G.jpg';
import Huawei_P40_Pro_Plus from './images/Huawei_P40_Pro_Plus.jpg';
import Huawei_P50_Pocket from './images/Huawei_P50_Pocket.jpg';
import Huawei_P50_Pro from './images/Huawei_P50_Pro.jpg';
import Huawei_P60_Pro from './images/Huawei_P60_Pro.jpg';
import Xiaomi_11_Lite_5G_NE from './images/Xiaomi_11_Lite_5G_NE.jpg';
import Xiaomi_11T from './images/Xiaomi_11T.jpg';
import Xiaomi_11T_Pro from './images/Xiaomi_11T_Pro.jpg';
import Xiaomi_12 from './images/Xiaomi_12.jpg';
import Xiaomi_12_Pro from './images/Xiaomi_12_Pro.jpg';
import Xiaomi_12T from './images/Xiaomi_12T.jpg';
import Xiaomi_12T_Pro from './images/Xiaomi_12T_Pro.jpg';
import Xiaomi_12X_5G from './images/Xiaomi_11T.jpg';
import Xiaomi_13_5G from './images/Xiaomi_13_5G.jpg';
import Xiaomi_13_Lite_5G from './images/Xiaomi_13_Lite_5G.jpg';
import Xiaomi_13_Pro_5G from './images/Xiaomi_13_Pro_5G.jpg';
import Xiaomi_13T_Pro from './images/Xiaomi_13T_Pro.jpg';
import Xiaomi_Mi_10_5G from './images/Xiaomi_Mi_10_5G.jpg';
import Xiaomi_Mi_10T_Pro_5G from './images/Xiaomi_Mi_10T_Pro_5G.jpg';
import Xiaomi_Mi_11 from './images/Xiaomi_Mi_11.webp';
import Xiaomi_Mi_11_Lite from './images/Xiaomi_Mi_11_Lite.jpg';
import Xiaomi_Mi_11_Lite_5G from './images/Xiaomi_Mi_11_Lite_5G.jpg';
import Xiaomi_Mi_11i from './images/Xiaomi_Mi_11i.jpg';
import Xiaomi_Mi_Note_10 from './images/Xiaomi_Mi_Note_10.webp';
import Xiaomi_Mi_Note_10_Pro from './images/Xiaomi_Mi_Note_10_Pro.jpg';
import Xiaomi_Poco_F3 from './images/Xiaomi_Poco_F3.jpg';
import Xiaomi_Poco_X3_NFC from './images/Xiaomi_Poco_X3_NFC.webp';
import Xiaomi_Poco_X3_Pro from './images/Xiaomi_Poco_X3_Pro.jpg';
import Xiaomi_Poco_X5 from './images/Xiaomi_Poco_X5.jpg';
import Xiaomi_Poco_X5_Pro from './images/Xiaomi_Poco_X5_Pro.jpg';
import Xiaomi_Redmi_Note_10_Pro from './images/Xiaomi_Redmi_Note_10_Pro.jpg';
import Xiaomi_Redmi_Note_10s from './images/Xiaomi_Redmi_Note_10s.jpg';
import Xiaomi_Redmi_Note_11 from './images/Xiaomi_Redmi_Note_11.jpg';
import Xiaomi_Redmi_Note_11_Pro from './images/Xiaomi_Redmi_Note_11_Pro.jpg';
import Xiaomi_Redmi_Note_11_Pro_5G from './images/Xiaomi_Redmi_Note_11_Pro_5G.jpg';
import Xiaomi_Redmi_Note_12_Pro_5G from './images/Xiaomi_Redmi_Note_12_Pro_5G.jpg';
import Xiaomi_Redmi_Note_12_Pro_Plus_5G from './images/Xiaomi_Redmi_Note_12_Pro_Plus_5G.jpg';
import Xiaomi_Redmi_Note_12_Pro_4G from './images/Xiaomi_Redmi_Note_12_Pro_4G.jpg';

import defaultPhone from './images/defaultPhone.png';

const modelImages = [
  { key: 'iPhone_11', value: iPhone_11 },
  { key: 'iPhone_11_Pro_Max', value: iPhone_11_Pro_Max },
  { key: 'iPhone_11_Pro', value: iPhone_11_Pro },
  { key: 'iPhone_12', value: iPhone_12 },
  { key: 'iPhone_12_mini', value: iPhone_12_mini },
  { key: 'iPhone_12_Pro', value: iPhone_12_Pro },
  { key: 'iPhone_12_Pro_Max', value: iPhone_12_Pro_Max },
  { key: 'iPhone_13', value: iPhone_13 },
  { key: 'iPhone_13_mini', value: iPhone_13_mini },
  { key: 'iPhone_13_Pro', value: iPhone_13_Pro },
  { key: 'iPhone_13_Pro_Max', value: iPhone_13_Pro_Max },
  { key: 'iPhone_14', value: iPhone_14 },
  { key: 'iPhone_14_eSim', value: iPhone_14_eSim },
  { key: 'iPhone_14_Plus', value: iPhone_14_Plus },
  { key: 'iPhone_14_Plus_eSim', value: iPhone_14_Plus_eSim },
  { key: 'iPhone_14_Pro', value: iPhone_14_Pro },
  { key: 'iPhone_14_Pro_eSIM', value: iPhone_14_Pro_eSIM },
  { key: 'iPhone_14_Pro_Max', value: iPhone_14_Pro_Max },
  { key: 'iPhone_14_Pro_Max_eSim', value: iPhone_14_Pro_Max_eSim },
  { key: 'iPhone_15', value: iPhone_15 },
  { key: 'iPhone_15_Plus', value: iPhone_15_Plus },
  { key: 'iPhone_15_Pro', value: iPhone_15_Pro },
  { key: 'iPhone_15_Pro_eSim', value: iPhone_15_Pro_eSim },
  { key: 'iPhone_15_Pro_Max', value: iPhone_15_Pro_Max },
  { key: 'iPhone_15_Pro_Max_eSim', value: iPhone_15_Pro_Max_eSim },
  { key: 'iPhone_SE_2020', value: iPhone_SE_2020 },
  { key: 'iPhone_SE_2022', value: iPhone_SE_2022 },
  { key: 'iPhone_X', value: iPhone_X },
  { key: 'iPhone_XR', value: iPhone_XR },
  { key: 'iPhone_XS', value: iPhone_XS },
  { key: 'iPhone_XS_Max', value: iPhone_XS_Max },
  { key: 'Samsung_Galaxy_A23_4G', value: Samsung_Galaxy_A23_4G },
  { key: 'Samsung_Galaxy_A23_5G', value: Samsung_Galaxy_A23_5G },
  { key: 'Samsung_Galaxy_A33_5G', value: Samsung_Galaxy_A33_5G },
  { key: 'Samsung_Galaxy_A34_5G', value: Samsung_Galaxy_A34_5G },
  { key: 'Samsung_Galaxy_A41', value: Samsung_Galaxy_A41 },
  { key: 'Samsung_Galaxy_A42_5G', value: Samsung_Galaxy_A42_5G },
  { key: 'Samsung_Galaxy_A51', value: Samsung_Galaxy_A51 },
  { key: 'Samsung_Galaxy_A51_5G', value: Samsung_Galaxy_A51_5G },
  { key: 'Samsung_Galaxy_A52', value: Samsung_Galaxy_A52 },
  { key: 'Samsung_Galaxy_A52_5G', value: Samsung_Galaxy_A52_5G },
  { key: 'Samsung_Galaxy_A52s_5G', value: Samsung_Galaxy_A52s_5G },
  { key: 'Samsung_Galaxy_A53_5G', value: Samsung_Galaxy_A53_5G },
  { key: 'Samsung_Galaxy_A54_5G', value: Samsung_Galaxy_A54_5G },
  { key: 'Samsung_Galaxy_A71', value: Samsung_Galaxy_A71 },
  { key: 'Samsung_Galaxy_A71_5G', value: Samsung_Galaxy_A71_5G },
  { key: 'Samsung_Galaxy_A72', value: Samsung_Galaxy_A72 },
  { key: 'Samsung_Galaxy_A72_5G', value: Samsung_Galaxy_A72_5G },
  { key: 'Samsung_Galaxy_Note10', value: Samsung_Galaxy_Note10 },
  { key: 'Samsung_Galaxy_Note10_5G', value: Samsung_Galaxy_Note10_5G },
  { key: 'Samsung_Galaxy_Note10_Plus', value: Samsung_Galaxy_Note10_Plus },
  { key: 'Samsung_Galaxy_Note20', value: Samsung_Galaxy_Note20 },
  { key: 'Samsung_Galaxy_Note20_5G', value: Samsung_Galaxy_Note20_5G },
  { key: 'Samsung_Galaxy_Note20_Ultra', value: Samsung_Galaxy_Note20_Ultra },
  { key: 'Samsung_Galaxy_Note20_Ultra_5G', value: Samsung_Galaxy_Note20_Ultra_5G },
  { key: 'Samsung_Galaxy_S10', value: Samsung_Galaxy_S10 },
  { key: 'Samsung_Galaxy_S10_Plus', value: Samsung_Galaxy_S10_Plus },
  { key: 'Samsung_Galaxy_S20', value: Samsung_Galaxy_S20 },
  { key: 'Samsung_Galaxy_S20_5G', value: Samsung_Galaxy_S20_5G },
  { key: 'Samsung_Galaxy_S20_FE', value: Samsung_Galaxy_S20_FE },
  { key: 'Samsung_Galaxy_S20_FE_5G', value: Samsung_Galaxy_S20_FE_5G },
  { key: 'Samsung_Galaxy_S20_Plus', value: Samsung_Galaxy_S20_Plus },
  { key: 'Samsung_Galaxy_S20_Plus_5G', value: Samsung_Galaxy_S20_Plus_5G },
  { key: 'Samsung_Galaxy_S20_Ultra_5G', value: Samsung_Galaxy_S20_Ultra_5G },
  { key: 'Samsung_Galaxy_S21_5G', value: Samsung_Galaxy_S21_5G },
  { key: 'Samsung_Galaxy_S21_FE_5G', value: Samsung_Galaxy_S21_FE_5G },
  { key: 'Samsung_Galaxy_S21_Plus_5G', value: Samsung_Galaxy_S21_Plus_5G },
  { key: 'Samsung_Galaxy_S21_Ultra_5G', value: Samsung_Galaxy_S21_Ultra_5G },
  { key: 'Samsung_Galaxy_S22_5G', value: Samsung_Galaxy_S22_5G },
  { key: 'Samsung_Galaxy_S22_Plus_5G', value: Samsung_Galaxy_S22_Plus_5G },
  { key: 'Samsung_Galaxy_S22_Ultra_5G', value: Samsung_Galaxy_S22_Ultra_5G },
  { key: 'Samsung_Galaxy_S23_5G', value: Samsung_Galaxy_S23_5G },
  { key: 'Samsung_Galaxy_S23_Plus_5G', value: Samsung_Galaxy_S23_Plus_5G },
  { key: 'Samsung_Galaxy_S23_Ultra_5G', value: Samsung_Galaxy_S23_Ultra_5G },
  { key: 'Samsung_Galaxy_S24_Ultra_5G', value: Samsung_Galaxy_S24_Ultra_5G },
  { key: 'Samsung_Galaxy_Z_Flip3_5G', value: Samsung_Galaxy_Z_Flip3_5G },
  { key: 'Samsung_Galaxy_Z_Flip4_5G', value: Samsung_Galaxy_Z_Flip4_5G },
  { key: 'Samsung_Galaxy_Z_Flip5', value: Samsung_Galaxy_Z_Flip5 },
  { key: 'Samsung_Galaxy_Z_Fold3_5G', value: Samsung_Galaxy_Z_Fold3_5G },
  { key: 'Samsung_Galaxy_Z_Fold4_5G', value: Samsung_Galaxy_Z_Fold4_5G },
  { key: 'Samsung_Galaxy_Z_Fold5', value: Samsung_Galaxy_Z_Fold5 },
  { key: 'Huawei_Mate_20_Pro', value: Huawei_Mate_20_Pro },
  { key: 'Huawei_Mate_30_Pro', value: Huawei_Mate_30_Pro },
  { key: 'Huawei_Mate_40_Pro_5G', value: Huawei_Mate_40_Pro_5G },
  { key: 'Huawei_Mate_50_Pro', value: Huawei_Mate_50_Pro },
  { key: 'Huawei_P20_Pro', value: Huawei_P20_Pro },
  { key: 'Huawei_P30', value: Huawei_P30 },
  { key: 'Huawei_P30_Lite', value: Huawei_P30_Lite },
  { key: 'Huawei_P30_Pro', value: Huawei_P30_Pro },
  { key: 'Huawei_P40', value: Huawei_P40 },
  { key: 'Huawei_P40_Lite', value: Huawei_P40_Lite },
  { key: 'Huawei_P40_Lite_5G', value: Huawei_P40_Lite_5G },
  { key: 'Huawei_P40_Pro_5G', value: Huawei_P40_Pro_5G },
  { key: 'Huawei_P40_Pro_Plus', value: Huawei_P40_Pro_Plus },
  { key: 'Huawei_P50_Pocket', value: Huawei_P50_Pocket },
  { key: 'Huawei_P50_Pro', value: Huawei_P50_Pro },
  { key: 'Huawei_P60_Pro', value: Huawei_P60_Pro },
  { key: 'Xiaomi_11_Lite_5G_NE', value: Xiaomi_11_Lite_5G_NE },
  { key: 'Xiaomi_11T', value: Xiaomi_11T },
  { key: 'Xiaomi_11T_Pro', value: Xiaomi_11T_Pro },
  { key: 'Xiaomi_12', value: Xiaomi_12 },
  { key: 'Xiaomi_12_Pro', value: Xiaomi_12_Pro },
  { key: 'Xiaomi_12T', value: Xiaomi_12T },
  { key: 'Xiaomi_12T_Pro', value: Xiaomi_12T_Pro },
  { key: 'Xiaomi_12X_5G', value: Xiaomi_12X_5G },
  { key: 'Xiaomi_13_5G', value: Xiaomi_13_5G },
  { key: 'Xiaomi_13_Lite_5G', value: Xiaomi_13_Lite_5G },
  { key: 'Xiaomi_13_Pro_5G', value: Xiaomi_13_Pro_5G },
  { key: 'Xiaomi_13T_Pro', value: Xiaomi_13T_Pro },
  { key: 'Xiaomi_Mi_10_5G', value: Xiaomi_Mi_10_5G },
  { key: 'Xiaomi_Mi_10T_Pro_5G', value: Xiaomi_Mi_10T_Pro_5G },
  { key: 'Xiaomi_Mi_11', value: Xiaomi_Mi_11 },
  { key: 'Xiaomi_Mi_11_Lite', value: Xiaomi_Mi_11_Lite },
  { key: 'Xiaomi_Mi_11_Lite_5G', value: Xiaomi_Mi_11_Lite_5G },
  { key: 'Xiaomi_Mi_11i', value: Xiaomi_Mi_11i },
  { key: 'Xiaomi_Mi_Note_10', value: Xiaomi_Mi_Note_10 },
  { key: 'Xiaomi_Mi_Note_10_Pro', value: Xiaomi_Mi_Note_10_Pro },
  { key: 'Xiaomi_Poco_F3', value: Xiaomi_Poco_F3 },
  { key: 'Xiaomi_Poco_X3_NFC', value: Xiaomi_Poco_X3_NFC },
  { key: 'Xiaomi_Poco_X3_Pro', value: Xiaomi_Poco_X3_Pro },
  { key: 'Xiaomi_Poco_X5', value: Xiaomi_Poco_X5 },
  { key: 'Xiaomi_Poco_X5_Pro', value: Xiaomi_Poco_X5_Pro },
  { key: 'Xiaomi_Redmi_Note_10_Pro', value: Xiaomi_Redmi_Note_10_Pro },
  { key: 'Xiaomi_Redmi_Note_10s', value: Xiaomi_Redmi_Note_10s },
  { key: 'Xiaomi_Redmi_Note_11', value: Xiaomi_Redmi_Note_11 },
  { key: 'Xiaomi_Redmi_Note_11_Pro', value: Xiaomi_Redmi_Note_11_Pro },
  { key: 'Xiaomi_Redmi_Note_11_Pro_5G', value: Xiaomi_Redmi_Note_11_Pro_5G },
  { key: 'Xiaomi_Redmi_Note_12_Pro_5G', value: Xiaomi_Redmi_Note_12_Pro_5G },
  { key: 'Xiaomi_Redmi_Note_12_Pro_Plus_5G', value: Xiaomi_Redmi_Note_12_Pro_Plus_5G },
  { key: 'Xiaomi_Redmi_Note_12_Pro_4G', value: Xiaomi_Redmi_Note_12_Pro_4G },
];


const ResponsiveImage = props => {
  const {
    className,
    rootClassName,
    alt,
    noImageMessage,
    image,
    variants,
    dimensions,
    publicData,
    ...rest
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const model = publicData?.Model;
  
  const modelImage = modelImages.find(x => x.key == model)?.value;
 
  if (image == null || variants.length === 0) {
    const noImageClasses = classNames(rootClassName || css.root, css.noImageContainer, className);

    const noImageMessageText = noImageMessage || <FormattedMessage id="ResponsiveImage.noImage" />;

    return (
      <div className={noImageClasses}>
            <div className={css.noImageWrapperDefaultPhone}>
              <div className={css.defaultPhoneDiv}>
                <img src={modelImage || defaultPhone} className={css.defaultImageSize}/>
              </div>
            </div>
      </div>
    );
  }

  const imageVariants = image.attributes.variants;

  const srcSet = variants
    .map(variantName => {
      const variant = imageVariants[variantName];

      if (!variant) {
        // Variant not available (most like just not loaded yet)
        return null;
      }
      return `${variant.url} ${variant.width}w`;
    })
    .filter(v => v != null)
    .join(', ');

  const imgProps = {
    className: classes,
    srcSet,
    ...rest,
  };

  return <img alt={alt} {...imgProps} />;
};

ResponsiveImage.defaultProps = {
  className: null,
  rootClassName: null,
  image: null,
  sizes: null,
  noImageMessage: null,
};

ResponsiveImage.propTypes = {
  className: string,
  rootClassName: string,
  alt: string.isRequired,
  image: oneOfType([propTypes.image, propTypes.imageAsset]),
  variants: arrayOf(string).isRequired,
  sizes: string,
  noImageMessage: string,
};

export default ResponsiveImage;
