import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';
import RED from './images/red.png';
import BLUE from './images/blue.png';
import SILVER from './images/silver.png';
import BLACK from './images/black.png';
import MIX from './images/mix.png';


const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    currentUser,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const companyName = author.attributes.profile?.publicData?.companyName;
  const userType = author.attributes.profile?.publicData?.userType;
  let monthlyQuantity = 0;
  if (userType == 'buyer') {
    monthlyQuantity = publicData?.monthlyQuantity || 0;
  }
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
      onMouseEnter: () => setActiveListing(currentListing.id),
      onMouseLeave: () => setActiveListing(null),
    }
    : null;

  const pickListingFields = (filteredConfigs, config) => {
    const { key, schemaType, enumOptions, includeForListingTypes, showConfig = {} } = config;
    const listingType = publicData.listingType;
    const isTargetListingType =
      includeForListingTypes == null || includeForListingTypes.includes(listingType);

    const { isDetail, label } = showConfig;
    const publicDataValue = publicData[key];
    const value = publicDataValue;

    if (isDetail && isTargetListingType && typeof value !== 'undefined') {
      const findSelectedOption = enumValue => enumOptions?.find(o => enumValue === `${o.option}`);
      const getBooleanMessage = value =>
        value
          ? intl.formatMessage({ id: 'SearchPage.detailYes' })
          : intl.formatMessage({ id: 'SearchPage.detailNo' });
      const optionConfig = findSelectedOption(value);

      return schemaType === 'enum'
        ? filteredConfigs.concat({ key, value: optionConfig?.label ? optionConfig?.label : value, label, enumOptions: enumOptions })
        : schemaType === 'boolean'
          ? filteredConfigs.concat({ key, value: getBooleanMessage(value), label, enumOptions: enumOptions })
          : schemaType === 'long'
            ? filteredConfigs.concat({ key, value, label, enumOptions: enumOptions })
            : filteredConfigs;
    }
    return filteredConfigs;
  };

  const existingListingFields = config.listing.listingFields.reduce(pickListingFields, []);
  const condition = config?.listing?.listingFields?.find(x => x.key == "Condition").enumOptions?.find(y => y.option == listing?.attributes?.publicData?.Condition)?.label;
  // const model = existingListingFields.find(key => key.key.toLowerCase().includes('Model'))?.value
  // const model = config?.listing?.listingFields?.find(x => x.key == "Model").enumOptions?.find(y => y.option == listing?.attributes?.publicData?.Model)?.label;
  const Storage = listing?.attributes?.publicData?.Storage;

  let colour = listing?.attributes?.publicData?.Colour;
  const plusLength = colour?.length - 4;

  if(plusLength > 0) {
    colour = colour.splice(0,5);
  }

  const isColourArray = Array.isArray(colour);

  const listingType = listing?.attributes?.publicData?.listingType;
  const model = config?.listing?.listingFields?.find(x => x.key == `${listingType}_model`)?.enumOptions?.find(y => y.option == listing?.attributes?.publicData?.Model)?.label;
  const isSubscription = currentUser?.attributes?.profile?.publicData?.isSubscription;

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        {firstImage ?
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={variants}
            sizes={renderSizes}
          />
          : <ResponsiveImage className={css.noImage} image={null} publicData={publicData} variants={[]} alt="" />
        }
      </AspectRatioWrapper>
      <div className={css.info}>
        {userType == 'seller' ?
          <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
          : null}
        <div className={css.mainInfo}>
          <div className={css.titleSection}>
            <div className={css.title}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}

            </div>
            <div className={css.mobStorage}> {Storage}</div>
          </div>
          {/* <div className={css.titleSection}>
            <div>
              Model
            </div>
            <div className={css.propertyValues}>
              {model}
            </div>
          </div> */}
          <div className={css.titleSection}>
            <div>
              Condition
            </div>
            <div className={css.propertyValues}>
              {condition}
            </div>
          </div>
          <div className={css.titleSection}>
            <div>
              Company
            </div>
            {isSubscription == true ? <div className={css.propertyValues}>
              {companyName}
            </div> : '****'}
          </div> 
          {userType == 'buyer' ?
            <div className={css.titleSection}>
              <div>
                Monthly Quantity
              </div>
              <div className={css.propertyValues}>
                {monthlyQuantity}
              </div>
            </div> : null}
          <div className={css.titleSection} style={{justifyContent:'flex-end', borderBottom:'0px'}}>
            <div className={css.propertyValues}>
              <div className={css.colorsImage}>
                {isColourArray ?
                  colour.length == 1 ?
                    <>
                      {colour[0] == 'mix' ? 
                        <div className={css.mixDiv}>
                          <div
                            style={{
                              borderRadius: '50%',
                              backgroundColor: colour[0],
                            }}

                          ><img src={MIX} /></div>
                          <span>  Mix</span>
                        </div> :
                        <div className={css.innerdiv}>
                          <div
                            style={{
                              width: '15px',
                              height: '15px',
                              borderRadius: '50%',
                              backgroundColor: colour[0],
                            }}
                            className={css.innerdiv}
                          >
                          </div>
                          <span>   {config?.listing?.listingFields?.find(x => x.key == "Colour").enumOptions?.find(y => y.option == colour[0])?.label}</span>
                        </div>


                      }
                    </>
                    :
                    <div className={css.colorcontainers}>

                      {colour?.map((item, index) => {
                        if((index > 3) && (plusLength > 0)) {
                          return (<div className={css.plusDiv}> + {plusLength} </div>);
                        }
                        return (
                          <>
                            <div className={css.innerdiv}>
                              {item == 'mix' ?
                                <div>
                                  <div
                                    style={{
                                      borderRadius: '50%',
                                      backgroundColor: item,
                                    }}

                                  ><img src={MIX} /></div>
                                  <span>  Mix</span>
                                </div> :
                                <div><div
                                  style={{
                                    width: '15px',
                                    height: '15px',
                                    borderRadius: '50%',
                                    backgroundColor: item,
                                  }}
                                  className={css.innerdiv}
                                ></div>

                                   <span>  {config?.listing?.listingFields?.find(x => x.key == "Colour").enumOptions?.find(y => y.option == item)?.label} </span>
                                </div>
                              }
                            </div>

                          </>
                        )
                      })}
                    </div>
                  : colour}
              </div>
            </div>
          </div>
          
          {/* <div className={css.titleSection}>
            {showAuthorInfo && userType == 'seller' ? (
              <div className={css.authorInfo}>
                <FormattedMessage id="ListingCard.author" values={{ authorName }} />
              </div>
            ) : null}
          </div> */}
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
